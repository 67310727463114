export enum Cookie {
    ZIP_CODE = 'zip_code',
    GALLONS = 'gallons',
    AUTH_TOKEN = 'auth_token',
    PRIVACY_CONFIRMED = 'privacy_confirmed',
    COMPANY_ID = 'company_id',
    USER_NAME = 'user_name',
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    USER_EMAIL = 'user_email',
    USER_ID = 'user_id',
    CART = 'cart',
    NAVIGATION_HISTORY = 'navigation_history',
    USER_CITY = 'user_city',
    USER_ROLE = 'user_role',
    TOKEN_EXPIRATION = 'token_expiration',
    REFRESH_TOKEN = 'refresh_token',
    SERVICE_ENABLED = 'service_enabled',
    OIL_DELIVERY_ENABLED = 'oil_delivery_enabled',
    APP_POPUP = 'app_popup',
    COMPANY_NAME = 'company_name',
    COMPANY_TRUCK = 'company_truck',
    COMPANY_TANK_INSPECTION = 'tank_inspection',
    PROPANEDELIVERYENABLED = 'propane-delivery-enabled',
    REFERRAL_CODE = 'referral_code',
    USER_ADDRESSS = 'user_addresses',
    WALLET_BALANCE = 'wallet_balance',
    REFERRAL_REWARD = 'total_referral_reward',
    ADDRESS_ID_SEO = 'address_id_seo',
}

export enum OrderStatus {
    Unknown = 0,
    Temporary = 1,
    Pending = 2,
    Scheduled = 3,
    Delivered = 4,
    Canceled = 5,
    DeliveryFailed = 6,
    PaymentFailed = 7,
    Giveup = 8,
}

export enum ServiceType {
    Unknown = 0,
    TuneUp = 1,
    ServiceCall = 2,
    OneYearContract = 3,
    OilDelivery = 4,
    All = 5,
    PropaneDelivery = 6,
}
