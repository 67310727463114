import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { AppService, Cookie, CookieService } from '@monorepo/utils';
import { appSettings, isCompanyApp } from '../utils/app.static';

@Injectable({
  providedIn: 'root'
})

export class CookieHelper {

  constructor(
    private cookieService: CookieService,
    private appService: AppService
  ) { }

  getAuthToken() {
    return this.getCookie(Cookie.AUTH_TOKEN);
  }

  getUserRole() {
    return this.getCookie(Cookie.USER_ROLE);
  }

  getCompanyID() {
    return this.getCookie(Cookie.COMPANY_ID);
  }

  getUserID() {
    return this.getCookie(Cookie.USER_ID);
  }

  getUserEmail() {
    return this.getCookie(Cookie.USER_EMAIL);
  }

  verifyAuthToken(token: string): boolean {
    try {
      let decoded: any = jwtDecode(token);
      if (decoded.hasOwnProperty("UserId")) {
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  setZipCode(zipCode: string) {
    if (!this.appService.isApp) {
      if (!this.appService.isLocal) {
        if (isCompanyApp) {
          this.setCookie(Cookie.ZIP_CODE, zipCode, appSettings.cookieDomain, '/');
        } else {
          this.setCookie(Cookie.ZIP_CODE, zipCode, '.heatfleet.com', '/');
        }
      } else {
        this.setCookie(Cookie.ZIP_CODE, zipCode);
      }
    } else {
      const expirationDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
      const expires = expirationDate.toUTCString();
      this.setCookie(Cookie.ZIP_CODE, zipCode, 'localhost', '/', expires);
    }
  }

  setGallons(gallons: string) {
    if (!this.appService.isApp) {
      if (!this.appService.isLocal) {
        if (isCompanyApp) {
          this.setCookie(Cookie.GALLONS, gallons, appSettings.cookieDomain, '/');
        } else {
          this.setCookie(Cookie.GALLONS, gallons, '.heatfleet.com', '/');
        }
      } else {
        this.setCookie(Cookie.GALLONS, gallons);
      }
    } else {
      const expirationDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
      const expires = expirationDate.toUTCString();
      this.setCookie(Cookie.GALLONS, gallons, 'localhost', '/', expires);
    }
  }

  setCookie(name: string, value: string, domain?: string, path?: string, expires?: string) {
    return this.cookieService.set(name, value, domain, path, expires);
  }

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  clearCookie() {
    return this.cookieService.deleteAll();
  }
}
